// Libraries

import React from "react"
import styled from "styled-components"
import ReactHtmlParser from "react-html-parser"
import { get } from "lodash"
import { graphql } from "gatsby"

import SEO from "../components/global/SEO"
import PageLayout from "../components/sharedPageLayout"

import { mobileSize, desktopSize } from "../helpers/sizeCalculations"

// Styles

// 404 Styles

const NotFoundPage = props => {
  const NotFoundContentWrapper = styled.div`
    min-height: 100vh;
    text-align: center;
  `
  const NotFoundSubtitle = styled.h2`
    padding: ${desktopSize(310)} 0 ${desktopSize(45)};
    margin: 0;

    @media (max-width: 768px) {
      padding: ${mobileSize(250)} 0 ${mobileSize(55)};
    }

    br {
      @media (max-width: 768px) {
        display: none;
      }
    }
  `

  const NotFoundContent = styled.div`
    p {
      margin-bottom: 0;
      font-family: "gt-sectra-fine-book", serif;
      font-size: 1.85vw;
      line-height: 1.3;

      @media (max-width: 768px) {
        font-size: 7.1vw;
      }

      br {
        @media (max-width: 768px) {
          display: none;
        }
      }
    }
  `

  //Assigning the props value to a variable. So that we avoid writing repeating node the get the adequate props value.
  const notFoundPage = props.data.allWordpressPage.nodes[0]

  const pageLayoutProps = {
    ...props,
    title: get(notFoundPage, "title"),
    image: get(notFoundPage, "custom.cookie_images"),
    showDividerInResponsive: false,
    showBackButton: false,
  }

  let notFoundPageContent = get(notFoundPage, "custom.cookie_content")
  let NotFoundPagetitle = get(notFoundPage, "custom.cookie_policy_sub_title")

  //create a custom subtitle and content structure. This will have different styling from other pages sub title
  const CONTENT_DETAILS = (
    <NotFoundContentWrapper>
      <NotFoundSubtitle className="ipad">
        {NotFoundPagetitle ? ReactHtmlParser(NotFoundPagetitle) : ""}
      </NotFoundSubtitle>
      <NotFoundContent className="ipad">
        {notFoundPageContent ? ReactHtmlParser(notFoundPageContent) : ""}
      </NotFoundContent>
    </NotFoundContentWrapper>
  )

  return (
    <>
      <SEO title="Page not found" />
      <PageLayout {...pageLayoutProps}>{CONTENT_DETAILS}</PageLayout>
    </>
  )
}

export default NotFoundPage

export const notFound = graphql`
  query pageNotFound {
    allWordpressPage(filter: { slug: { eq: "not-found" } }) {
      nodes {
        title
        custom {
          cookie_policy_sub_title
          cookie_content
          cookie_images {
            image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              alt_text
            }
          }
        }
      }
    }
  }
`
